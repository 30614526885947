.cardButtonRounded::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 2px;
    background: linear-gradient(to right, #C471ED, #4A90ED);
    -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.cardButtonRounded:active {
    background: linear-gradient(to right, #C471ED, #4A90ED);
}