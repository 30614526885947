.slider {
    height: 500px;
}

.slick-slide {
    padding: 0 10px;
}

.slick-slide.slick-active.slick-current {
    transform: scale(1);
    z-index: 1;
    filter: none;
}

.slick-slide {
    transform: scale(0.80);
    transition: filter 0.3s ease-in-out;
    filter: opacity(50%);
}