.card-item__side {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.card-item__side.-front {
  transform: rotateY(0deg);
}
.card-item__side.-back {
  transform: rotateY(180deg);
}