@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body,
html {font-family: "DM Sans", sans-serif;
  font-size: 16px;
}

.MuiInputBase-root {
  background-color: #ffffff !important;
}

.MuiInputBase-input .MuiOutlinedInput-input {
  background-color: #ffffff !important;
}

input {
  background-color: #ffffff !important;
}

.css-1pa6dq4 {
  background-color: #ffffff !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11.5px 20px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  /*width: 60px !important;*/
}
