$black: #333333;
$blue: #686de0;
$gray: #d3d3d3;
$green: #6ab04c;
$red: #dc3545;
$white: #ffffff;

.pin-field {
  border: 1px solid #d8dbe0;
  border-radius: 0.25rem;
    //border-right: none;
    //font-size: 2rem;
    //height: 4rem;
    //outline: none;
    text-align: center;
    transition-duration: 250ms;
    transition-property: color, border, box-shadow, transform;
    width: 3rem;
  //height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.25rem;
  margin: 0 .25rem;
  font-size: .875rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

&:first-of-type {
     //border-radius: 0.25rem 0 0 0.25rem;
 }

&:last-of-type {
     //border-radius: 0 0.25rem 0.25rem 0;
     //border-right: 1px solid $gray;
 }

&:focus {
  //borderColor: '#958bef',
  //outline: 0,
  //boxShadow: '0 0 0 0.2rem rgb(50 31 219 / 25%)'
     border-color: #958bef;
     box-shadow: 0 0 0 0.2rem rgb(50 31 219 / 25%);
     outline: 0;

& + .pin-field {
      border-left-color: #958bef;
  }
}

&:invalid {
     animation: shake 5 linear 75ms;
     border-color: $red;
     box-shadow: 0 0 0.25rem rgba($red, 0.5);

& + .pin-field {
      border-left-color: $red;
  }
}

&.complete {
     border-color: $green;
     color: $green;

&[disabled] {
     background: rgba($green, 0.1);
     cursor: not-allowed;
     opacity: 0.5;
 }

& + .pin-field {
      border-left-color: $green;
  }
}
}

//@media (min-width: 768px) {
//  .pin-field {
//    /* Ajoutez ici les styles pour les écrans plus larges */
//    width: 5rem; /* Par exemple, une largeur de 5rem pour les tablettes */
//    font-size: 1rem; /* Par exemple, une taille de police plus grande pour les tablettes */
//  }
//}

/* Styles spécifiques pour les écrans plus étroits (par exemple, les smartphones) */
@media (max-width: 576px) {
  .pin-field {
    width: 2rem; /* Par exemple, une largeur de 3rem pour les smartphones */
    font-size: 0.75rem; /* Par exemple, une taille de police plus petite pour les smartphones */
    alignment: center;
  }
}


@keyframes shake {
    from {
        transform: translateY(-5%);
    }
    to {
        transform: translateY(5%);
    }
}