.buttonContainer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
}

.activeNotifier {
    display: none;
}

.timeContainer {
    display: none;
}