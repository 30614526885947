.display {
    display: none;
}

a:hover {
    color: #0000ff; /* #0ab4ff */
    font-size: 1.1rem;
}

.menu-anchor:hover {
    background-color: #cafce1 !important;
    font-weight: bolder;
}

.ps-menu-button:hover {
    background-color: #C7C7C7 !important;
}

.ps-sidebar-container {
    width: inherit;
    position: fixed !important;
}

.menuLogo > .ps-menu-button:hover {
    background-color: revert !important;
}

.ps-menuitem-root.ps-active > .ps-menu-button > .ps-menu-icon > svg > * {
    stroke: #ffffff;
}