@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.rotate {
    animation: rotate .5s linear infinite; /* Répétez l'animation indéfiniment */
}
.loading {
    transition: filter 0.3s ease-in-out;
    filter: opacity(50%);
}